const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: 'https://rea.okta.com/oauth2/default',
    clientId: '0oa1ht190ltUYpPd81d8',
    redirectUri: window.location.origin + '/implicit/callback',
    // pkce: false
};

const oktaSignInConfig = {
    logo: '//logo.clearbit.com/rea-group.com',
    features: {
        rememberMe: true,
        router: false
    },
    baseUrl: 'https://rea.okta.com',
    clientId: '0oa1ht190ltUYpPd81d8',
    redirectUri: window.location.origin + '/implicit/callback',
    authParams: {
        // If your app is configured to use the Implicit Flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        issuer: 'https://rea.okta.com/oauth2/default',
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile'],
        pkce: true
    }
};

export { oktaAuthConfig, oktaSignInConfig };
