import React, {Component} from 'react';
import logo from '../assets/benny_button_logo.png';
import gif from '../assets/inhale-exhale.gif';
import '../App.css';


class Profiler extends Component {

    constructor(props) {
        super(props);
        this.state = { visible:false }
    }


    componentDidMount() {
        setTimeout( function() {
            this.setState({visible:true});
        }.bind(this),30000 );
    }


    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="Bb-logo" alt="logo"/>
                    <div className={this.state.visible? 'Profiler-link-fadeOut':'Profiler-link-fadeIn'}>
                        <p>Your Benny Button Profiler is just 3 breaths away...</p>
                    </div>
                    <img src={gif} className="Bb-gif" alt="inhale-exhale-animation"/>
                    <div className={this.state.visible? 'Profiler-link-fadeIn':'Profiler-link-fadeOut'}>
                        <a className="App-link" href="https://rea-profiler.bennybutton.com" target="_self"
                           rel="noopener noreferrer">
                            ...click here to go to your Benny Button Profiler.
                        </a>
                    </div>
                </header>
            </div>
        );
    }

}

export default Profiler
