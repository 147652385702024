import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';
import {ReactTypeformEmbed} from 'react-typeform-embed';
import logo from "../assets/benny_button_logo.png";
import gif from "../assets/inhale-exhale.gif";

const Home = () => {

    const [userInfo, setUserInfo] = useState( null );
    const [ready, setReady] = useState( false );
    const [error, setError] = useState( null );

    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();

    if (authState.isPending) return null;

    const login = async () => history.push('/login');

    const logout = async () => oktaAuth.signOut();

    const button = authState.isAuthenticated ?
        <button onClick={logout}>Logout</button> :
        <button onClick={login}>Login</button>;


    const linkToTypeformForm = ( userInfo ) => {
        if ( userInfo !== null ) {
            let employeeNumber = userInfo.preferred_username
            // console.log( "Form Typeform URL for REA user with email `" + userInfo.email + "` and employee number `" + employeeNumber + "`..." );
            if ( employeeNumber === undefined ) {
                employeeNumber = userInfo.email.substring(0, userInfo.email.lastIndexOf("@"));
            }
            console.log( `Typeform Form query: https://bennybutton.typeform.com/to/ovMDsc?email=${userInfo.email}&employeeid=${employeeNumber}` )
            return `https://bennybutton.typeform.com/to/ovMDsc?email=${userInfo.email}&employeeid=${employeeNumber}`;
        }
        return `https://bennybutton.typeform.com/to/ovMDsc`;
    }



    if (authState.isPending) return null;

    if ( authState.isAuthenticated && userInfo === null ) {
        oktaAuth.getUser().then( (info) =>
            {
                console.log( "Got a user... check the employee number" )
                // this.setState( info )
                // this.state.userInfo = info
                // this.state.ready = true
                setUserInfo( info )
                setReady( true )
                setError( null )
            }
        ).catch( (exception) => {
            console.error( exception )
            setUserInfo( null )
            setReady( false )
            setError( exception.message )
        } )
    }

    if ( ready === true ) {
        console.log( "Render the typeform link" )
        return (
            <div className="App">
                <ReactTypeformEmbed url={linkToTypeformForm( userInfo )}/>
            </div> );
    }
    else if ( ready === false ) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="Bb-logo" alt="logo"/>
                    <div className='Profiler-link-fadeIn'>
                        <p>Loading...</p>
                    </div>
                    <img src={gif} className="Bb-gif" alt="inhale-exhale-animation"/>
                </header>
            </div>
        )
    }
    else if ( error !== null ) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="Bb-logo" alt="logo"/>
                    <div className='Profiler-link-fadeIn'>
                        <p>Unfortunately there was an error and we are not able to determine your okta user data: </p>
                        <p>{error}</p>
                    </div>
                    <img src={gif} className="Bb-gif" alt="inhale-exhale-animation"/>
                </header>
            </div>
        )
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="Bb-logo" alt="logo"/>
                <img src={gif} className="Bb-gif" alt="inhale-exhale-animation"/>
            </header>
        </div>
    )
};
export default Home;
