import React from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignInWidget from '../OktaSignInWidget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const Login = ({ config }) => {
    const { oktaAuth, authState } = useOktaAuth();

    // const checkValidAuthentication = () => {
    //     if ( oktaAuth.getRefreshToken() !== undefined )
    //         return true;
    //     else
    //         return false;
    // }

    const onSuccess = (tokens) => {
        oktaAuth.handleLoginRedirect(tokens);
        oktaAuth.storeTokensFromRedirect()
    };

    const onError = (err) => {
        console.log( 'Error logging in to Okta Benny Button Assessment app...', err );
    };

    if ( authState.isPending ) return null;

    // return authState.isAuthenticated || checkValidAuthentication() === true ?
    return authState.isAuthenticated ?
        <Redirect to={{ pathname: '/' }}/> :
        <OktaSignInWidget
            config={config}
            onSuccess={onSuccess}
            onError={onError}/>;
};
export default Login;
