import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const   OktaSignInWidget = ({ config, onSuccess, onError }) => {
    const widgetRef = useRef();
    useEffect(() => {
        if (!widgetRef.current)
            return false;

        const widget = new OktaSignIn( config );

        const authClient = widget.authClient

        // widget.hasTokensInUrl

        // if ( authClient.token.hasTokensInUrl() ) {
        //     authClient.token.parseTokensFromUrl(
        //         function success(tokens) {
        //             // Save the tokens for later use, e.g. if the page gets refreshed:
        //             // Add the token to tokenManager to automatically renew the token when needed
        //             tokens.forEach(token => {
        //                 if (token.idToken) {
        //                     authClient.tokenManager.add('idToken', token);
        //                 }
        //                 if (token.accessToken) {
        //                     authClient.tokenManager.add('accessToken', token);
        //                 }
        //             });
        //
        //             // Say hello to the person who just signed in:
        //             let idToken = authClient.tokenManager.get('idToken');
        //             console.log('Hello, ' + idToken.claims.email);
        //
        //             // Remove the tokens from the window location hash
        //             window.location.hash='';
        //         },
        //         function error(err) {
        //             // handle errors as needed
        //             console.error( err );
        //         }
        //     );
        // } else {
        //     widget.showSignInToGetTokens({
        //         el: widgetRef.current,
        //     }).then(onSuccess).catch(onError);
        //
        //     return () => widget.remove();
        // }


        // // remove the following when finished testing...
        // const tokenManager = widget.authClient.tokenManager
        // let tokens = tokenManager.getTokens().then( ( tokens ) => {
        //     console.log( "-> got tokens: " + tokens.accessToken + " | " + tokens.idToken )
        // } ).catch( (error) => {
        //     console.error( "Error while trying to get tokens... " +  error )
        // } )
        // // remove the above when finished testing...

        authClient.session.get().then( (res) => {

            if ( res.status === 'ACTIVE' ) {
                console.log('Welcome back ' + res.login + ' at the REA Benny Button Dashboard app...' );

                widget.authClient.token.getWithoutPrompt().then( (receivedToken) => {
                    console.log( "tokens received: " + receivedToken.tokens.accessToken )
                    onSuccess( receivedToken.tokens )
                }).catch( (error) => {
                    console.error( error )
                } )

                // return res;
                // widget.remove();
                // onSuccess( res.user.token )
                // return (<Redirect to={{ pathname: '/' }}/>)
                // res.session.setCookieAndRedirect(config.redirectUri);
                // return () => widget.remove();
            }
            else {
                widget.showSignInToGetTokens({
                    el: widgetRef.current,
                }).then(onSuccess).catch(onError);
            }
        })

        return () => widget.remove();


    });

    return (<div ref={widgetRef} />);
};
export default OktaSignInWidget;
