const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: 'https://dev-812847.okta.com/oauth2/default',
    clientId: '0oa2db37f9gZhIioW4x7',
    // redirectUri: window.location.origin + '/implicit/callback',
    redirectUri: 'http://localhost:3000/implicit/callback',
    pkce: true,
    scopes: ['openid', 'profile', 'email']
};

const oktaSignInConfig = {
    logo: '//logo.clearbit.com/rea-group.com',
    features: {
        rememberMe: true,
        router: false
    },
    baseUrl: 'https://dev-812847.okta.com',
    clientId: '0oa2db37f9gZhIioW4x7',
    redirectUri: window.location.origin + '/implicit/callback',
    authParams: {
        issuer: 'https://dev-812847.okta.com/oauth2/default',
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile'],
        // If your app is configured to use the Implicit Flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        pkce: true
    }
};

export { oktaAuthConfig, oktaSignInConfig };
