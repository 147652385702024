import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import {OktaAuth} from '@okta/okta-auth-js';
import Home from './Home';
import Login from './Login';
// import {oktaAuthConfig, oktaSignInConfig} from './../config/index';
import config from '../config';
import Survey from "./Survey";
import Profiler from "./Profiler";
// import LoginThroughOkta from "./LoginThroughOkta";


const oktaAuth = new OktaAuth(config.oktaAuthConfig);

const AppWithRouterAccess = () => {
    const history = useHistory();



    const customAuthHandler = () => {
        history.push('/login');
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
        >
            <Switch>
                <SecureRoute path='/' exact={true} component={Home} />
                <SecureRoute path='/survey' component={Survey} />
                <Route path='/login' render={() => <Login config={config.oktaSignInConfig} />} />
                 {/*<Route path='/login' exact component={LoginThroughOkta}/>*/}
                <Route path='/implicit/callback' component={LoginCallback} />
                <Route path='/pressure-valve' component={Profiler} />
            </Switch>
        </Security>
    );
};
export default AppWithRouterAccess;
